<script setup lang="ts">
import { useVuelidate } from '@vuelidate/core'
import { required, email, minLength } from '@vuelidate/validators'

import { useAuth } from '@/stores/authentication'

import constants from '@/config/constants'

import { useModal } from '~/stores/modal'

const { SIGN_IN } = useAuthService()

const Router = useRouter()

const formData = reactive({
    email: '',
    password: '',
})

const formValidations = computed(() => {
    return {
        email: { email, required },
        password: { required, min: minLength(8) },
    }
})

const v$ = useVuelidate(formValidations, formData)

const validationMessages = computed(() => ({
    email:
        v$.value.email.required.$invalid && v$.value.$dirty
            ? '*El email es requerido*'
            : v$.value.email.email.$invalid && v$.value.$dirty
            ? '*Debe ser un email valido*'
            : false,

    password:
        v$.value.password.required.$invalid && v$.value.$dirty
            ? '*La contraseña es requerida*'
            : v$.value.password.min.$invalid && v$.value.$dirty
            ? '*Debe tener mínimo 8 caracteres*'
            : false,
}))

const isLoading = ref(false)

const signIn = async () => {
    v$.value.$touch()

    if (v$.value.$invalid) return

    isLoading.value = true
    try {
        const response = await SIGN_IN(formData)

        if (response.access_token) {
            const AuthStore = useAuth()

            AuthStore.setAuthData({
                profile: response.user,
                token: response.access_token,
                status: true,
            })

            formData.email = ''
            formData.password = ''

            v$.value.$reset()

            const now = new Date()

            const expirationDate = new Date(new Date().setFullYear(now.getFullYear() + 1))

            const tokenCookie = useCookie(constants.authToken, {
                expires: expirationDate,
                path: '/',
            })

            tokenCookie.value = response.access_token

            Router.push('/')
        } else {
            const ModalStore = useModal()

            ModalStore.setModal({
                name: 'ModalDefault',
                info: {
                    text: 'Sus datos no coinciden, verifique y vuelva a intentarlo.',
                    continue: {
                        handler: () => null,
                        text: 'Intentar nuevamente',
                    },
                },
            })
        }
    } catch (e: any) {
        console.log('SignIn error: ', { e })

        const ModalStore = useModal()

        if (e.status === 401) {
            ModalStore.setModal({
                name: 'ModalDefault',
                info: {
                    text: 'Datos no validos, verifica e intenta de nuevo.',
                    cancel: {
                        handler: () => null,
                        text: 'Intentar nuevamente',
                    },
                },
            })
            isLoading.value = false
            return
        }
        ModalStore.setModal({
            name: 'ModalDefault',
            info: {
                text: 'Algo salio mal, contacta el soporte.',
                continue: {
                    handler: () => Router.push('/#formulario-de-contacto'),
                    text: 'De acuerdo',
                },
                cancel: {
                    handler: () => null,
                    text: 'Intentar nuevamente',
                },
            },
        })
    }

    isLoading.value = false
}
</script>

<template>
    <main class="login">
        <div class="login__login">
            <h1>Únete a la colección <br />Milenio Arte</h1>
            <form @submit.prevent="signIn" id="form" class="login__form">
                <div class="form__social">
                    <BtnGoogle />
                    <BtnFacebook />
                </div>
                <div class="form__or">
                    <span>Inicia sesión con tu correo electrónico</span>
                    <hr />
                </div>
                <div class="form__input">
                    <label for="sign-in-email">Correo electrónico</label>
                    <input
                        for="sign-in-email"
                        type="email"
                        placeholder="Inserta tu correo electrónico"
                        v-model="formData.email"
                    />
                    <UtilsFormValidation
                        class="error"
                        v-if="validationMessages.email"
                        :validation="validationMessages.email"
                    />
                </div>
                <div class="form__input">
                    <label for="sign-in-password">Contraseña</label>
                    <input
                        for="sign-in-password"
                        type="password"
                        placeholder="Inserta tu contraseña"
                        v-model="formData.password"
                    />
                    <UtilsFormValidation
                        class="error"
                        v-if="validationMessages.password"
                        :validation="validationMessages.password"
                    />
                </div>
                <div class="form__more">
                    <div class="check">
                        <input type="checkbox" id="sign-in-remember" name="remember" value="remember" />
                        <label for="sign-in-remember">Mantenme conectado</label>
                    </div>
                    <NuxtLink to="/recuperar">¿Has olvidado tu contraseña?</NuxtLink>
                </div>
                <button class="form__submit-btn" type="submit">
                    <p v-if="!isLoading">Iniciar sesión</p>
                    <img
                        v-else
                        src="/assets/png/loading-white.png"
                        alt="Cargando"
                        width="48px"
                        height="48px"
                    />
                </button>
                <div class="form__no-acc">
                    <p>¿No tienes una cuenta?</p>
                    <NuxtLink to="/registro">Regístrate</NuxtLink>
                </div>
            </form>
        </div>
        <div class="login__image">
            <img src="https://i.imgur.com/S8LUrRC.jpeg" alt="" />
            <div class="image__artist">
                <img class="image__artist-photo" src="https://i.imgur.com/C2uTRVH.png" alt="" />
                <div class="image__artist-info">
                    Artista:
                    <p>Aidee De león</p>
                </div>
            </div>
        </div>
    </main>
</template>

<style lang="postcss" scoped>
.login {
    @apply flex justify-center xl:justify-between text-center container gap-0 2xl:gap-14 my-10 xl:my-0;
    & h1 {
        @apply font-Pathway font-bold text-[28px] leading-[35px] lg:text-[36px] lg:leading-[44px];
    }
    &__login {
        @apply 2xl:pl-16 flex flex-col justify-center items-center;
    }
    &__image {
        @apply hidden xl:block relative;
        & .image__artist {
            @apply absolute top-10 right-10 bg-black backdrop-blur-sm bg-opacity-60 px-12 flex items-center gap-5 py-4;
            &-photo {
                @apply w-12 h-12 rounded-full;
            }
            &-info {
                @apply font-Inter text-[14px] text-white text-left border-l pl-4;
                & p {
                    @apply font-Pathway text-[19px] font-bold;
                }
            }
        }
    }
    &__form {
        @apply pt-8 flex flex-col gap-2 text-left w-full max-w-md;
        & label {
            @apply font-Inter font-medium text-[12px] lg:text-[14px];
        }
        & .form__social {
            & button {
                @apply border flex items-center justify-center gap-2 px-4 py-3 w-full font-Inter font-semibold text-[15px] hover:bg-gray-100 transition;
            }
            & button:first-child {
                @apply mb-2;
            }
        }
        & .form__or {
            @apply relative mt-4 lg:mt-6 lg:mb-2 text-center;
            & span {
                @apply bg-white px-4 font-Inter font-medium text-[13px] lg:text-[16px];
            }
            & hr {
                @apply absolute inset-0 w-full border-t border-[#E4E4E4] mt-3 z-[-1];
            }
        }
        & .form__input {
            @apply w-full mt-3;
            & input {
                @apply mt-2 w-full border border-[#E4E4E4] px-4 py-3 font-Inter text-[14px] lg:text-[15px] focus:outline-none focus:ring-1 focus:ring-site-primary transition pr-12;
            }
        }
        & .form__submit-btn {
            @apply mt-4 bg-black px-4 h-12 flex justify-center font-Inter items-center text-[15px] text-white font-semibold;
            img {
                @apply w-8 h-8 block animate-spin;
            }
        }
        & .form__more {
            @apply flex justify-between items-center mt-4;
            & .check {
                @apply flex items-center gap-2;
                & input {
                    @apply w-4 h-4 border rounded-sm focus:ring-1 focus:outline-none transition border-[#E4E4E4] text-site-primary focus:border-site-primary focus:ring-site-primary;
                }
                & label {
                    @apply font-Inter font-medium text-[13px] lg:text-[16px];
                }
            }
            & a {
                @apply text-[#C69E2B] font-Inter font-semibold text-[11px] lg:text-[12px];
            }
        }
        & .form__no-acc {
            @apply flex items-center gap-4 mt-4;
            & p {
                @apply font-Inter text-[13px] lg:text-[16px] text-[#5F6D7E];
            }
            & a {
                @apply text-[#206570] font-Inter font-semibold text-[15px] pt-0.5;
            }
        }
    }
}
</style>
~/stores/authentication
